<template>
<div> 
  <!-- select 2 demo -->
    <b-modal
      id="modal-select2"
      title="Vendor Details"
      centered
      ok-only
      size="md"
    >
 <b-card  :img-src="detailObj.cover" img-height="250px"  img-alt="Cake image" img-top>
        <!-- <img :src="cover" />  -->
           <template #header>
              <h4 class="mb-0">{{detailObj.bakeryName}}</h4>
               <hr>
              </template>
             
    <b-row>
    <b-col>
        <b-card-text>
          <h5>About Us </h5> <p>  {{this.detailObj.aboutUs}} </p>
        </b-card-text>
    </b-col>
  </b-row>
        <b-row>
    <b-col>
        <b-card-text>
    <h5>Location </h5> <p>  {{ this.detailObj.address}} </p>
        </b-card-text>
    </b-col>
  </b-row>
 <b-row v-for="pt in partners" v-bind:key="pt.bpartnerId">
    <b-col >
        <b-card-text>
          <h5>Partners </h5>
          <b-row>
       
       <b-col>
      <h6> Email </h6> <p>  {{ pt.email}} </p>
       </b-col>
       <b-col>
     <h6> EmiratesId </h6> <p>  {{ pt.emiratesId}} </p>
       </b-col>
          </b-row>
   
        </b-card-text>
    </b-col>
  </b-row>
 <b-row>
  
    <b-col>
       <h5>Documents</h5>
        <gallery :images="docs" :index="index" @close="index = null"></gallery>
    <div
      class="image"
      v-for="image, imageIndex in docs"
      :key="imageIndex"
      @click="index = imageIndex"
      :style="{ backgroundImage: 'url(' + image + ')', width: '100px', height: '100px' }"
    ></div>
    </b-col>
  </b-row>

<b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" @click="ApproveVendor(detailObj.userId)">Approve</b-button>
<b-button class="ml-1" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="DeclineVendor(detailObj.userId)">Decline</b-button>
      </b-card>

     
    </b-modal>
  <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
       @ok="Filter()"
      
      title="Filters"
    >
      <b-form>
       <b-form-group>
          <h5>Select date range</h5>
       <div>
      <flat-pickr 
      v-model="date.range"
      class="form-control" 
      :config="{ allowInput: true, mode: 'range' }"
      
      ></flat-pickr>
     </div>
       </b-form-group>
        <br>
        <b-form-group>
           <h5>Select filter</h5>
            <v-select
                      v-model="filterData"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productfilterOptions"
                      
                      label="text"
                      :clearable="false"
                      placeholder="Select Filter"
                    />
                
        </b-form-group>
      </b-form>         
    </b-modal>

  <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

           <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-start">
               <div style="padding-right:15px">
                <b-button
                  v-b-modal.modal-login
                  variant="primary"
                >
                  <span class="text-nowrap">Filters</span>
                </b-button>
              </div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>


  <div>
    <b-table :items="items" :fields="fields" >

      <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item  v-b-modal.modal-select2  @click="openDetails(data.item.vendorId)">
              <feather-icon icon="FileTextIcon" />
              
              <span class="align-middle ml-50"   >Details</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="AcceptOrder(data.item.orderId)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50" >Accept order</span>
            </b-dropdown-item>

            <b-dropdown-item @click="DenyOrder(data.item.orderId)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Decline order</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
    </b-table>
<div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

  </div>
  </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component'
import { BFormSelect } from 'bootstrap-vue'
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';

export default {
  components: {
    //Multiselect,
    
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    //BImg ,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
'gallery': VueGallery
  },
   props: {
   /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
   },
created()
{
 this.LoadData();
},
  data(){
    return {
      filterData:'',
      date:{
        range:'',
      },
      index:null,
      cover:'',
      docs:[],
      partners:[],
      fields:[{label:'Name',key:'bakeryName'} ,{label:'City',key:'city'} ,{label:'Types',key:'productType'},{label:'Status',key:'status'},'actions' ],
      items:[],
      selected: null,
        filterOptions: [
          { text: 'Most sold product', value: 'mostSold' },
          { text: 'Least sold product', value: 'leastSold' },
          { text: 'Most popular product', value: 'mostPopular' },
          { text: 'Least popular product', value: 'leastPopular' },
          { text: 'Most profitable product', value: 'mostProfitable' },
        ],
       rangeDate: null,
        myObj: {
        orderId: 0,
        customerId : 0,
        bill:0,
        discount:0,
        modeOfpayment:'',
        vendorId : 0,
        deliveryCharges:0,
        date : '',
        totalBill: 0
      },
  detailObj:{},
  }
  
  },
  methods: 
  {
    Filter()
    { 
     // console.log("Osama");
      console.log(this.date.range , this.filterData.value);
      var split = this.date.range.split(" ");
      var from = split[0];
      var to = split[2];

       console.log(from); 
    },
    ApproveVendor(id)
  {
   console.log(id);
Swal.fire({
  title: 'Do you want to approve that Bakery?',
  text: "You won't be able to revert this!",
  icon:'question',
  iconColor:'#FB595f',
  confirmButtonText: `Accept`,
  confirmButtonColor:'#FB595f',
  
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
  var axios = require('axios');
  var config = {
    method: 'put',
    url: 'https://okaaik.fastech.pk/api/vendors/vendorStatus/?id='+ id +'&status=true' ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };
 console.log(config.url);
  axios(config)
  .then( (response) => {
     console.log(JSON.stringify(response.data));
     if(response.data.status === "success")
     {
      Swal.fire('Accepted!', '', 'success').then((res)=>{
      this.$bvModal.hide('modal-select2');      
      this.LoadData();
    })
     }
 })
  .catch(function (error) {
    console.log(error);
  });

  } 
})

    },
    DeclineVendor(id)
    {
Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, deny it!'
}).then((result) => {
  if (result.isConfirmed) {
 var axios = require('axios');
  var config = {
    method: 'put',
    url: 'https://okaaik.fastech.pk/api/vendors/vendorStatus/?id='+ id +'&status=false' ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };
 console.log(config.url);
  axios(config)
  .then( (response) => {
     console.log(JSON.stringify(response.data));
     if(response.data.status === "success")
     {
      Swal.fire(
      'Denied!',
      'Your order has been denied.',
      'success'
    ).then((res)=>{
     this.$bvModal.hide('modal-select2'); 
      this.LoadData();
    })
     }
 })
  .catch(function (error) {
    console.log(error);
  });


   
  }
})
 

    },
    openDetails(id)
    {
      this.detailObj = [];
      this.partners=[];
      this.docs=[];
     console.log(id);
      var axios = require('axios');

  var config = {
    method: 'get',
    url: 'https://okaaik.fastech.pk/api/vendors/vendorDetails/'+  id ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };

  axios(config)
  .then( (response) => {
    // console.log(JSON.stringify(response.data));
   
    //  this.data  =  response.data;
    // console.log( response.data);
    this.detailObj = response.data.vendor[0];
    this.partners.push(response.data.partners[0]);
    
    //this.docs.push(response.data.documents);

    console.log(this.partners);
    //console.log(this.docs);
  response.data.documents.forEach(elem => {
              this.docs.push(elem.documentPath);
            })
            console.log(this.docs);
   // this.detailObj = response.data[0]
    // console.log( this.detailObj);
     //this.cover = this.detailObj.coverImg;
      //console.log( this.cover);
 })
  .catch(function (error) {
    console.log(error);
  });
    },
AcceptOrder(id)
{
Swal.fire({
  title: 'Do you want to accept the order?',
  text: "You won't be able to revert this!",
  icon:'question',
  iconColor:'#FB595f',
  confirmButtonText: `Accept`,
  confirmButtonColor:'#FB595f',
  
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
  var axios = require('axios');
  var config = {
    method: 'put',
    url: 'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id='+ id +'&check=true' ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };
 console.log(config.url);
  axios(config)
  .then( (response) => {
     console.log(JSON.stringify(response.data));
     if(response.data.status === "success")
     {
      Swal.fire('Accepted!', '', 'success').then((res)=>{

      this.LoadData();
    })
     }
 })
  .catch(function (error) {
    console.log(error);
  });

  } 
})
},
DenyOrder(id)
{
Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, deny it!'
}).then((result) => {
  if (result.isConfirmed) {
 var axios = require('axios');
  var config = {
    method: 'put',
    url: 'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id='+ id +'&check=false' ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };
 console.log(config.url);
  axios(config)
  .then( (response) => {
     console.log(JSON.stringify(response.data));
     if(response.data.status === "success")
     {
      Swal.fire(
      'Denied!',
      'Your order has been denied.',
      'success'
    ).then((res)=>{

      this.LoadData();
    })
     }
 })
  .catch(function (error) {
    console.log(error);
  });


   
  }
})
 


},
  LoadData() {
  var axios = require('axios');

  var config = {
    method: 'get',
    url: 'https://okaaik.fastech.pk/api/vendors/Getvendors/' ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };

  axios(config)
  .then( (response) => {
    // console.log(JSON.stringify(response.data));
   
    //  this.data  =  response.data;
    // console.log( response.data);
    this.items = response.data;

     console.log(this.items);
 })
  .catch(function (error) {
    console.log(error);
  });
    },  
  },
  

  // setup() {
  //   const ORDER_APP_STORE_MODULE_NAME = 'app-order'

  //   // Register module
  //   // if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)

  //   // // UnRegister on leave
  //   // onUnmounted(() => {
  //   //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
  //   // })

  //   /*const modalFilterOptions = [
  //     { label: 'Most sold product', value: 'mostSold' },
  //     { label: 'Least sold product', value: 'leastSold' },
  //     { label: 'Most popular product', value: 'mostPopular' },
  //     { label: 'Least popular product', value: 'leastPopular' },
  //     { label: 'Most profitable product', value: 'mostProfitable' },
  //   ]*/


  //   const {
     
  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,

  //    // modalFilter,
  //   } = useOrdersList()

  //   return {

  //     // Sidebar
  //     //isAddNewOrderSidebarActive,

  //     fetchMyOrders,
  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,
  //     // Filter
  //     avatarText,
  //     //avatarText,
  //     //modalFilterOptions,
  //     //modalFilter,     

  //   }
  // },
 
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style> 